import React from 'react'
import SEO from '../components/seo'
import LandingGallery from '../Views/Home/LandigPage/LandingGallery'

const LoginPage = () => {	
	
	return (
		<>
			<SEO title="gallery" />
				<LandingGallery />
		</>
	)
}

export default LoginPage
