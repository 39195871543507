import React from 'react'
import '../../../assets/css/landingstyles.css'
import LandingLayout from './Layout/LandingLayout'
import ScrollingGallery from '../../../components/primitives/ScrollingGallery'
import { navigate } from 'gatsby'


const LandingGallery = () => {
    return (
        <LandingLayout>
            <div>
                <div className="gallery-1">
                    <div className="galler-wrapper">
                        <ScrollingGallery topList={true} midList={false}/>
                    </div>
                </div>
                <div className="question section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="question-left">
                                <h2>Wanna see your photo?</h2>
                            </div>
                            <div className="question-right">
                                <div className="banner-btn-wrapper">
                                    <a href={false} onClick={() => navigate("/login")} className="btn">Login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gallery-1">
                    <div className="galler-wrapper">
                    <ScrollingGallery topList={false} midList={true} />
                    </div>
                </div>
                <div className="question section-gap color-blue">
                    <div className="container">
                        <div className="row">
                            <div className="question-left">
                                <h2>Have a question, or didn't find what you were looking for?</h2>
                            </div>
                            <div className="question-right">
                                <div className="banner-btn-wrapper">
                                    <a href="#" className="btn btn-blue">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gallery-1">
                    <div className="galler-wrapper">
                    <ScrollingGallery topList={false} midList={false}/>
                    </div>
                </div>
            </div>

        </LandingLayout>
    )
}

export default LandingGallery
